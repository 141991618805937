import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/kylesoeltz/git/reconnect-v2/src/components/layouts/post-layout.js";
import { graphql } from "gatsby";
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        authors {
          id
          name
        }
        categories {
          id
          name
        }
      }
    }
    allMdx(
      sort: {fields: [frontmatter___date], order: DESC}
      limit: 6
      filter: {frontmatter: {category: {eq: "hiking-in-new-england" }}}
    ) {
      edges {
        node {
          frontmatter {
            author
            date
            path
            title
            featuredImage
          }
          slug
          timeToRead
          excerpt(truncate: false, pruneLength: 200)
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Figcaption = makeShortcode("Figcaption");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><strong parentName="p">{`Miles:`}</strong>{` 12.3 including Mt. Height; 12.1 without`}</p>
    <p><strong parentName="p">{`Elevation Gain:`}</strong>{` 4600'`}</p>
    <p><strong parentName="p">{`Difficulty:`}</strong>{` Hard`}</p>
    <p><img parentName="p" {...{
        "src": "carter-notch-loop-map-compressed-378x500.jpg",
        "alt": "Map of the Carter Notch Loop"
      }}></img></p>
    <Figcaption mdxType="Figcaption">A map of the Carter Notch Loop. The main trail is highlighted in red, green is the alternate Mt. Height bypass trail, and blue shows the logging road cutoff route. [View Larger Map.](/images/carter-notch-loop-map-compressed.jpg)</Figcaption>
    <p>{`The Carter Notch loop is the best way to hit all three of the Carters (and the incredible Mt. Height) in one go. If you are using any maps, such as the `}<a parentName="p" {...{
        "href": "https://www.amazon.com/Mountains-National-Forest-Appalachian-Mountain/dp/1628420774/ref=as_li_ss_tl?ie=UTF8&qid=1521140268&sr=8-2&keywords=white+mountain+maps&linkCode=ll1&tag=reconnectlife-20&linkId=894c1428fc36ec198a3a8c39a94a09a5"
      }}>{`AMC’s`}</a>{` or `}<a parentName="p" {...{
        "href": "https://www.amazon.com/Mountain-National-Forest-Geographic-Illustrated/dp/159775224X/ref=as_li_ss_tl?s=books&ie=UTF8&qid=1521140424&sr=1-1&keywords=white+mountain+map+national+geographic&linkCode=ll1&tag=reconnectlife-20&linkId=8c59314184e80bc2e82cded66e5b27d5"
      }}>{`National Geographic’s`}</a>{`, you won’t find the loop on there. These maps make it look as though you have to do a long road walk or a lot of backtracking if you wanted to grab all three in one day. But thanks to an old logging road that’s not on the maps, you can grab all three of these New Hampshire 4000 footers in a nice, full loop.`}</p>
    <h2>{`Start From the Nineteen Mile Brook Trailhead`}</h2>
    <p><img parentName="p" {...{
        "src": "19-mile-brook-compressed-500x500.jpg",
        "alt": "19 Mile Brook"
      }}></img></p>
    <Figcaption mdxType="Figcaption">The 19 Mile Brook that the trail is named after.</Figcaption>
    <p>{`Park your car at the Nineteen Mile Brook Trailhead and begin your hike. You will stay on Nineteen Mile Brook for about 3.7 miles, all the way to the Carter Lakes. The trail is easy, as the grade is very low. You will gradually gain around 1970 feet of elevation. Once you reach the first Carter Lake, you begin to make your way around it before hitting a junction. Here you will turn left onto the Carter-Moriah Trail.`}</p>
    <p><img parentName="p" {...{
        "src": "carter-notch-lake-compressed-500x328.jpg",
        "alt": "Carter Notch Lake"
      }}></img></p>
    <Figcaption mdxType="Figcaption">Carter Notch Lake.</Figcaption>
    <h2>{`Turn Left Onto The Carter-Moriah Trail`}</h2>
    <p>{`The Nineteen Mile Brook Trail will hopefully have warmed you up, as the grade is about to go from gradual to incredibly steep. This is the hardest climb of the loop as you will nearly double the elevation you’ve done so far over the next mile. You will go up around 1500 feet from the junction to the summit of Carter Dome, the first 4000 footer of the day.`}</p>
    <p>{`The summit has some nice views as well as the remains of an old firetower. After taking in the views, you will continue along the Carter-Moriah Trail for about .4 miles downhill until reaching another junction`}{`*`}{`. Here you can choose to continue on the Carter-Moriah Trail up over Mt. Height or bypass it by taking the trail around it. Both trails will lead to the same point.`}</p>
    <h2>{`Option 1 - Mt. Height and the Carter-Moriah Trail`}</h2>
    <p>{`I highly recommend taking the route up Mt. Height, which will add 0.2 miles and 136 feet of elevation gain to your hike. Its 360 degree open summit may be the best views you get along this loop. To reach mount Height, turn right at the fork and continue along the Carter-Moriah Trail.`}</p>
    <p><img parentName="p" {...{
        "src": "IMG_20171113_105922_114-500x452.jpg",
        "alt": "Views off Mt. Height"
      }}></img></p>
    <Figcaption mdxType="Figcaption">Views looking off of Mt. Height.</Figcaption>
    <p>{`Once you’ve taken in the views, you will descend the trail until you meet back up with the bypass. Turn right and you will continue onto the Carter-Moriah Trail. Shortly afterwards you will reach the junction for the Carter Dome Trail and the Carter-Moriah Trail. Keep going along the Carter-Moriah Trail.`}</p>
    <h2>{`Option 2 - Bypass Mt. Height`}</h2>
    <p>{`If you choose not to do Mt. Height, then you save .2 miles and about 136 feet of elevation, as mentioned above. This brings your day’s stats to 12.1 miles and 4464 feet of elevation.`}</p>
    <p>{`Once you meet back up with the Mt. Height cutoff, continue straight on the Carter-Moriah trail until you reach the junction for the Carter Dome Trail and the Carter-Moriah Trail. Keep going along the Carter-Moriah Trail.`}</p>
    <h2>{`Middle Carter`}</h2>
    <p>{`From the junction you have about 1.8 miles and another 950 feet of elevation until reaching Middle Carter. There are some nice ridgeline views as you approach the summit, but the summit itself is in the trees.`}</p>
    <p><img parentName="p" {...{
        "src": "middle-carter-ridge-compressed-500x500.jpg",
        "alt": "Middle Carter Ridge"
      }}></img></p>
    <Figcaption mdxType="Figcaption">The open ridge leading up to Middle Carter.</Figcaption>
    <p>{`As you continue along the trail you will reach the junction for the North Carter Trail, but for now you will want to continue straight along the Carter-Moriah Trail.`}</p>
    <h2>{`North Carter and the North Carter Trail`}</h2>
    <p>{`You’re now just a short quarter of a mile and about 100 feet of elevation until the last 4000 footer of the day.`}</p>
    <p>{`Once you hit the peak, you will head back down to the North Carter Trail junction and turn onto it. Follow the North Carter Trail for 1.2 miles until you reach the junction for the Imp Trail. `}</p>
    <h2>{`Left Onto the Imp Trail`}</h2>
    <p>{`At the Imp Trail junction, you will want to turn left. Follow the Imp Trail for about 2 miles and you will come onto a very obvious logging road. It cuts across the trail in both directions and is wide enough that a car could fit on it. It should be very hard to miss.`}</p>
    <p><img parentName="p" {...{
        "src": "imp-trail-evening-light-compressed-500x328.jpg",
        "alt": "Imp Trail Evening Light"
      }}></img></p>
    <Figcaption mdxType="Figcaption">Some beautiful evening light coming through the trees along the Imp Trail.</Figcaption>
    <h2>{`Left Onto the Logging Road`}</h2>
    <p>{`The logging road is very straightforward. Follow it for a little ways and it will take you out to the back of Camp Dodge. Walk through Camp Dodge back to the road and turn left on the road. You will have a very brief road walk back to the Nineteen Mile Brook trailhead.`}</p>
    <p><img parentName="p" {...{
        "src": "imp-trail-logging-road-closeup-compressed-500x500.jpg",
        "alt": "Imp Trail Logging Road Map"
      }}></img></p>
    <Figcaption mdxType="Figcaption">A close up of the logging road cutoff. [View Larger Map.](/images/imp-trail-logging-road-closeup-compressed.jpg)</Figcaption>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      